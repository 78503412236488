import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DialogOverView, DownloadComponent } from './download/download.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { TeamComponent } from './team/team.component';
import { DonateComponent } from './donate/donate.component';
import { HomeComponent } from './home/home.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { HttpClientModule } from '@angular/common/http';
import { DeviceComponent } from './device/device.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { AdsenseModule } from 'ng2-adsense';
import { GalleryComponent } from './gallery/gallery.component';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ArchiveComponent } from './archive/archive.component';
import { SnowFlakeComponent } from './snow/snow.component';
import { FaqComponent } from './faq/faq.component';
import { FormsModule } from '@angular/forms';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DownloadComponent,
    ChangelogComponent,
    TeamComponent,
    DonateComponent,
    HomeComponent,
    DisclaimerComponent,
    PrivacyComponent,
    TermsComponent,
    DeviceComponent,
    DocumentationComponent,
    GalleryComponent,
    DialogOverView,
    ArchiveComponent,
    SnowFlakeComponent,
    FaqComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatExpansionModule,
    MatSnackBarModule,
    ClipboardModule,
    MatChipsModule,
    MatDialogModule,
    HttpClientModule,
    PerfectScrollbarModule,
    FormsModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-1149172094904803',
      adSlot: 7259985550
      })
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
