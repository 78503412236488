import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ElixirService } from '../elixir.service';

export interface DialogData {
  buildVersion: string
  codeName: string
  deviceChange: string
  deviceId: string
  deviceName: string
  deviceStatus: string
  device_logo: string
  downloadLink: string
  directdownloadLink: string
  guide: string
  maintainer: string
  telegram: string
  xdaLink: string
}

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {

  downloadItemList: any = [];
  deviceListToBeShown: any = [];
  filteredListOfDevices: any = [];
  showFooter: boolean
  windowScrolled: boolean;
  isChecked: boolean;

  constructor(private elixirService: ElixirService, private router: Router, @Inject(DOCUMENT) private document: Document, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.isChecked = true;
    this.elixirService.getDownloadList().subscribe(download => { this.downloadItemList = download, this.checkToggleState() })
    this.checkToggleState();
    this.showFooter = (this.router.url === '/download') ? true : false;
  }

  checkToggleState() {
    this.downloadItemList.forEach(el => {
      if (this.isChecked && el.deviceDetails) {
        this.filteredListOfDevices.push({'deviceCategory':el.deviceCategory, 'deviceDetails' :el.deviceDetails.filter(a => a.deviceStatus === "active")})  
      }
    });
    this.deviceListToBeShown = this.isChecked ? this.filteredListOfDevices : this.downloadItemList ;
    return this.deviceListToBeShown;
  }

  // setDeviceDetails(deviceid){
  //   localStorage.setItem('deviceId',deviceid);
  // }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  openDialog(buttonData) {
    const dialogRef = this.dialog.open(DialogOverView, {
      width: '450px',
      data: buttonData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

/** dialog container component details */
@Component({
  selector: 'dialog-overview',
  templateUrl: 'dialog-overview.html',
  styleUrls: ['./download.component.scss']
})
export class DialogOverView {

  constructor(public dialogRef: MatDialogRef<DialogOverView>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}