<div style="background-color: #ffe9e9;" class="container-fliud ">

  <br>

  <div class="container website_animation">
    <h1 class="text-center text-uppercase"
      style="font-weight: 600; padding-top: 4rem; font-size: 2.5rem; margin-bottom: 1.7rem;">Frequently used <strong
        class="key-high">Files</strong> are here</h1>
    <p>Trying to keep records and availability of files that you use and access often, such as documents, mods, or apk etc.</p>
  </div>

</div>

<div class="container-fliud">

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <div class="container files_card mb-5 website_animation shadow-light" style="background: white;">

    <table class="table">
      <thead class="table-head">
        <tr>
          <th scope="col">File Name</th>
          <th scope="col">Device Specific</th>
          <th scope="col">Download Link</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ar of archiveList">
          <td> {{ar.filename}} </td>
          <td> {{ar.devicespecific}} </td>
          <td><a style="color: #cf7b7d; font-weight: bold;" href="{{ar.link}}">Download Here</a></td>
        </tr>
      </tbody>
    </table>

  </div>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <div class="container website_animation">
    <h3><strong>Disclaimer</strong></h3>
    <h5>- All the <strong class="key-high">Credit</strong> goes to their respective owners.</h5>
    <h5 class="note">- In case of any issues or removal contact <a class="key-high"
        href="https://telegram.me/ugly_kid_af">here.</a> </h5>
  </div>

</div>

<!-- Footer Support Card -->
<div class=" mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">
  <div class="row justify-content-center">
    <div class=" mb-3 card-radius">
      <div class="row no-gutters">

        <div class="col-md-6">
          <div class="card-body">
            <h2 class="support_line"> Wanna Support Us ? </h2>
            <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
              amount is appreciated.<strong>シ </strong>
            </p>
            <h1> <a href="https://projectelixiros.com/donate">
                <a class="btn shadow footer-support-btn">Donate Now</a>
              </a></h1>

          </div>
        </div>

        <div class="col-md-6 mb-align" style="text-align: center;">
          <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
            style="width: 15rem;" alt="avatar">
        </div>

      </div>
    </div>
  </div>
</div>

<div>
  <app-footer></app-footer>
</div>