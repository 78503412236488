import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear : number;
  lastYear : number;

  constructor() { }

  ngOnInit(): void {
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.lastYear = this.currentYear - 1;
  }

}
