<div class="container-fliud">
  <div class="header">
    <h1>DISCLAIMER </h1>
  </div>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <p>Last updated: 2022-01-30</p>
  <p><b>WEBSITE DISCLAIMER</b></p>
  <p>The information provided by <b>Project Elixir</b> (“Company”, “we”, “our”, “us”) on <b>projectelixiros.com</b>
    (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith,
    however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy,
    validity, reliability, availability, or completeness of any information on the Site.</p>
  <p>UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT
    OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR
    RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.</p>
  <p><b>EXTERNAL LINKS DISCLAIMER</b></p>
  <p>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or
    originating from third parties or links to websites and features. Such external links are not investigated,
    monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.</p>
  <p>WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
    INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY
    BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
    TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <p><b>ERRORS AND OMISSIONS DISCLAIMER</b></p>
  <p>While we have made every attempt to ensure that the information contained in this site has been obtained from
    reliable sources, Project Elixir is not responsible for any errors or omissions or for the results obtained from
    the use of this information. All information in this site is provided “as is”, with no guarantee of
    completeness, accuracy, timeliness or of the results obtained from the use of this information, and without
    warranty of any kind, express or implied, including, but not limited to warranties of performance,
    merchantability, and fitness for a particular purpose.</p>
  <p>In no event will Project Elixir, its related partnerships or corporations, or the partners, agents or employees
    thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in
    this Site or for any consequential, special or similar damages, even if advised of the possibility of such
    damages.</p>

  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>

  <p><b>LOGOS AND TRADEMARKS DISCLAIMER</b></p>
  <p>All logos and trademarks of third parties referenced on projectelixiros.com are the trademarks and logos of their
    respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval,
    endorsement or sponsorship of Project Elixir by such owners.</p>
  <p><b>CONTACT US</b></p>
  <p>Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by
    email: <b>support@projectelixiros.com</b>.</p>
  <br>
  <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
  </ng2-adsense>
</div>

<!-- Footer Support Card -->
<div class=" mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">
  <div class="row justify-content-center">
    <div class=" mb-3 card-radius">
      <div class="row no-gutters">

        <div class="col-md-6">
          <div class="card-body">
            <h2 class="support_line"> Wanna Support Us ? </h2>
            <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
              amount is appreciated.<strong>シ </strong>
            </p>
            <h1> <a href="https://projectelixiros.com/donate">
                <a class="btn shadow footer-support-btn">Donate Now</a>
              </a></h1>

          </div>
        </div>

        <div class="col-md-6 mb-align" style="text-align: center;">
          <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
            style="width: 15rem;" alt="avatar">
        </div>

      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>