import { Component, OnInit } from '@angular/core';
import { ElixirService } from '../elixir.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {

  archiveList;

  constructor(private archiveService : ElixirService) { }

  ngOnInit(): void {
    this.archiveService.getArchiveDetails().subscribe(
      data => { this.archiveList = data, console.log(this.archiveList)}
    )
  }

}
