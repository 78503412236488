<!-- FAQ Container -->


<div class="page-heading">
    <p class="page-heading website_animation"> Your <strong class="key-high">Questions</strong> are answered here
    </p>
</div>


<div class="high-card-1-top ">
    <div class="row no-gutters website_animation">

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head ">What is the Project Elixir ?</h5>
                <p class="card-text ">Project Elixir is a custom ROM created by us to use on mobile devices,
                    such as
                    smartphones and
                    tablets. We all know that it is Google that provides the Android Platform and its
                    maintenance.
                    Based
                    on
                    which OEMs build their own custom Firmware/Skin/ROM such as Xiaomi's MIUI, Samsung's
                    OneUI,
                    OnePlus'
                    OxygenOS, etc. Due to the fact that Android is an open-source platform, developers can
                    build
                    their
                    own
                    ROM/Firmware/Skin and that is what we commonly refer to as Custom ROM. Unlike OEMs,
                    Custom ROM
                    developers
                    provide frequent updates, a bloatware-free, and ad-free experience with great
                    flexibility when
                    it
                    comes to
                    the usability of the OS. </p>
            </div>
        </div>
        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/elixir-newlogo.webp" class="faq-img" style="width: 13rem;" alt="img">
        </div>
    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<div class="non-high-card website_animation">
    <div class="row no-gutters">

        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/faq-2.webp" class="faq-img" alt="img">
        </div>

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head ">Know the process</h5>
                <p class="card-text ">We optimize Google Open Source code for seamless experience & security
                    as well
                    as
                    customize it based on user requirements, with pixel goodies and other features. In other
                    words
                    it
                    starts
                    with the Google Open Source Code. First step is to extract the source code.
                    Only after the code extraction, modifications can be done. Customization along with
                    other UI/UX
                    related
                    changes are made at this point by our maintainers. Later on, after device specific
                    changes are
                    done,the
                    code is compilied together and released as 'Custom ROM'. </p>
            </div>
        </div>

    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<div class="high-card website_animation">
    <div class="row no-gutters">

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head ">Ports v/s Custom rom</h5>
                <p class="card-text ">Custom ROMs are built from the source code (AOSP) provided by Google
                    specifically
                    for
                    respective devices. Custom ROMs can be made from a scratch source or a very stable
                    source. In
                    order
                    to
                    build a custom ROM, there are certain pre-requisite such as High-End Building Server
                    (Jenkins)
                    or PC
                    having very High-End technical specification combined with a very High-Speed internet
                    connection.
                    Whereas, Ports cannot be compiled from the scratch source code. PORTS are simply
                    extracted from
                    the
                    OEMs
                    particular devices such as MIUI, OneUI, OxygenOS, etc, and are being made compatible for
                    a
                    specific
                    device
                    in order to flash and boot it. For instance, OxygenOS 6T PORT for Redmi Note 7 Pro PORTS
                    made
                    for a
                    specific device generally come with compatibility issues,
                    some of the features won't work at all and may harm the device in a long run. Unlike
                    Custom ROM
                    in
                    order
                    to build a PORT, generally, developers do not need a High-End Server and can be made
                    from a
                    Low-End
                    PC.
                </p>
            </div>
        </div>
        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/faq-3.webp" class="faq-img" alt="img">
        </div>
    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<div class="non-high-card website_animation">
    <div class="row no-gutters">

        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/faq-4.webp" class="faq-img" alt="img">
        </div>

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head ">Need for Monetry Support</h5>
                <p class="card-text ">As a matter of fact that in order to build a Custom ROM there are
                    certain
                    pre-requisite such as a High-End Building Server (Jenkins) or PC having very High-End
                    technical
                    specification combined with a very High-Speed internet connection.
                    In order to maintain the source, we need High-End Servers that are generally very
                    expensive and
                    need
                    to be
                    renewed every single month. For users to download the ROM at high speed and keeping the
                    backup
                    file
                    as
                    well we need good quality cloud storage.
                    We developers need to allot our time and hard work from our Academic's hefty schedule in
                    the
                    proper
                    maintenance of the custom ROM so that we can provide users with timely and stable
                    updates.
                    To meet with our technical expenses, a cup of coffee, and the efforts of the developers
                    we need
                    your
                    monetary support! </p>
            </div>
        </div>

    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<div class="high-card website_animation">
    <div class="row no-gutters">

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head ">What we do ?</h5>
                <p class="card-text ">First step is to extract the source code. Only after the code
                    extraction,
                    modifications can be done. Customization along with other UI/UX related changes are made
                    at this
                    point by
                    our maintainers. Later on, after device specific changes are done,the code is compilied
                    together
                    and
                    released as 'Custom ROM'. In Other words we optimize Google Open Source code for
                    seamless
                    experience
                    &
                    security as well as
                    customize it based on user requirements, with pixel goodies and other features. </p>
                <p class="note2"><strong>Note :</strong> AOSP custom ROMs are entirely different from OEM
                    ports.
                </p>
            </div>
        </div>
        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/faq-5.webp" class="faq-img" alt="img">
        </div>
    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<div class="non-high-card website_animation">
    <div class="row no-gutters">

        <div class="col-md-6 mb-align faq-img-div">
            <img src="./assets/images/faq-6.webp" class="faq-img" alt="img">
        </div>

        <div class="col-md-6">
            <div class="card-body">
                <h5 class="card-title f_head">Why donate us ?</h5>
                <p class="card-text ">Our developers and maintainers need servers to maintain ROM source
                    codes &
                    build
                    ROMs for quick updates and great features. And to build and maintain a Custom ROM there
                    are
                    certain
                    pre-requisite such as a
                    High-End Building Server (Jenkins) or PC having very High-End technical specification
                    combined
                    with
                    a very
                    High-Speed internet connection. So we use Jenkins or Paid Shared Server for compiling
                    the code
                    which
                    are
                    expensive and we have pay monthly for it. And as a student we find it pretty expensive.
                    So It
                    will
                    be
                    really helpful and
                    appreciated if you donate or contribute to us.</p>
                <p class="note2"><strong>Note :</strong> Jenkins used for compiling the code are expensive
                    and we
                    have
                    pay
                    monthly for it.</p>
            </div>
        </div>
    </div>
</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="7769138215"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>



<br>

<!-- Footer Support Card -->
<div class=" mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">
    <div class="row justify-content-center">
        <div class=" mb-3 card-radius">
            <div class="row no-gutters">

                <div class="col-md-6">
                    <div class="card-body">
                        <h1 class="support_line"> Wanna Support Us ? </h1>
                        <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
                            amount is appreciated.<strong>シ </strong>
                        </p>
                        <h1> <a href="https://projectelixiros.com/donate">
                                <a class="btn shadow footer-support-btn">Donate Now</a>
                            </a></h1>

                    </div>
                </div>

                <div class="col-md-6 mb-align" style="text-align: center;">
                    <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
                        style="width: 15rem;" alt="avatar">
                </div>

            </div>
        </div>
    </div>
</div>


<div>
    <app-footer></app-footer>
</div>