import { Component, OnInit } from '@angular/core';
import { ElixirService } from '../elixir.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {

  gpay_id = "dwarmachine24@oksbi";
  deviceId : any;
  allDeviceList : any = [];
  selectedDevice : any = [];
  durationInSeconds = 5;

  constructor(private elixirService : ElixirService, private _snackBar: MatSnackBar) {
    // this.deviceId  = localStorage.getItem('deviceId')
   }

  ngOnInit(): void {
    this.deviceId = window.location.pathname.split('/device/')[1];
    this.elixirService.getDownloadList().subscribe( download => { 
      this.allDeviceList = download; 
      this.allDeviceList.forEach( obj => {
        obj.deviceDetails.forEach(el => {
          if(el.deviceId === this.deviceId){
            this.selectedDevice.push(el);         
          }
        });
      });
      return this.selectedDevice; 
    })
  }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackComponent, {
      duration: this.durationInSeconds * 500,
      horizontalPosition: 'center',
      verticalPosition:'top'
    });
  }
     
}

@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar-component.html',
  styles: [`
   
    ::ng-deep .mat-snack-bar-container{ 
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
    .snackbox {
      color: #155724;
    }
  `],
})

export class SnackComponent {}
