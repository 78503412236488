<footer class="footer-bg">
    <div>

        <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
            style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
            data-full-width-responsive="true">
        </ng2-adsense>

        <!-- Footer -->
        <footer class="page-footer font-small stylish-color-dark pt-4">

            <!-- Footer Links -->
            <div class="text-center text-md-left">

                <!-- Grid row -->
                <div class="row">

                    <!-- About & Icons column -->
                    <div class="col-md-4 mx-auto">

                        <!-- About ROM Content -->
                        <h5 class="font-weight-bold mt-3 mb-3">シ Project <strong
                                style="font-size: 2rem; font-family: 'PT Sans'; color: #cf7b7d;">Elixir</strong> </h5>
                        <p class="card-text foot-sum">A custom ROM based on AOSP, which offers a minimal UI enhancement
                            & close
                            to stock pixel
                            Android ROM with great <strong style="font-family: 'PT Sans';">"Performance", "Security" and
                                "Stability".</strong></p>
                        <!-- <p style="font-weight: 600;">Email: <strong class="text-muted"
                                style="font-weight: 500;">support@projectelixiros.com</strong> </p> -->
                        <p style="font-weight: 600;"> Email:<a class="url" style="font-weight: 500;" target="_blank"
                                href="mailto:support@projectelixiros.com?subject=From Website related to Elixir query"><a> Tap here to contact us</a>
                            </a></p>

                        <!-- Github, Download, Donate, Telegram Icons -->
                        <div class="list-unstyled list-inline ">
                            <a target="_blank" href="https://telegram.me/Elixir_Discussion">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="#000000"
                                    class="bi bi-telegram footer_logo_icons" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                                </svg> </a>

                            <a target="_blank" href="https://www.paypal.me/uglykid24"> <svg
                                    xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="#000000"
                                    class="bi bi-paypal footer_logo_icons" viewBox="0 0 16 16">
                                    <path
                                        d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z" />
                                </svg> </a>

                            <a target="_blank" href="https://twitter.com/Projectelixiros"><svg
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="74" height="74"
                                    fill="#000000" class="footer_logo_iconsX" style="margin-left: 0.7rem;"
                                    viewBox="0 0 50 50">
                                    <path
                                        d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z">
                                    </path>
                                </svg></a>

                            <a target="_blank" href="https://www.patreon.com/join/uglykid24">
                                <svg width="34" height="34" viewBox="0 -4.5 256 256" version="1.1"
                                    class="footer_logo_icons" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
                                    <g>
                                        <path
                                            d="M45.1355837,0 L45.1355837,246.35001 L0,246.35001 L0,0 L45.1355837,0 Z M163.657111,0 C214.65668,0 256,41.3433196 256,92.3428889 C256,143.342458 214.65668,184.685778 163.657111,184.685778 C112.657542,184.685778 71.3142222,143.342458 71.3142222,92.3428889 C71.3142222,41.3433196 112.657542,0 163.657111,0 Z"
                                            fill="#000000"></path>
                                    </g>
                                </svg>
                            </a>

                            <a target="_blank" href="https://github.com/Project-Elixir"> <svg
                                    xmlns="http://www.w3.org/2000/svg" width="38" height="38" fill="#000000"
                                    class="bi bi-github footer_logo_icons" viewBox="0 0 16 16">
                                    <path
                                        d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                                </svg> </a>

                        </div>

                    </div>


                    <!-- Useful 3 row Links column -->
                    <!-- Docs column -->
                    <hr class="clearfix w-100 d-md-none">
                    <div class="side-links mx-auto">
                        <p class="font-weight-bold text-uppercase">Documentation</p>
                        <ul class="list-unstyled">
                            <li>
                                <a class="url " href="https://projectelixiros.com/documentation">Apply for
                                    Maintainership</a>
                            </li>
                            <li>
                                <a class="url" href="https://projectelixiros.com/documentation">Maintainers Code</a>
                            </li>
                            <li>
                                <a class="url" href="https://projectelixiros.com/documentation">Device Requirements</a>
                            </li>
                        </ul>
                    </div>

                    <!-- Help & Support column -->
                    <hr class="clearfix w-100 d-md-none">
                    <div class="side-links mx-auto">

                        <p class="font-weight-bold text-uppercase">Help & Support</p>
                        <ul class="list-unstyled">
                            <li>
                                <a target="_blank" class="url" href="https://telegram.me/Elixir_Updates">Telegram
                                    Channel</a>
                            </li>
                            <li>
                                <a target="_blank" class="url" href="https://projectelixiros.com/documentation">Telegram
                                    Group</a>
                            </li>
                            <li>
                                <a target="_blank" class="url" href="https://github.com/ProjectElixir-Devices">Device
                                    Organisation </a>
                            </li>

                        </ul>

                    </div>

                    <!-- Legal Info column -->
                    <hr class="clearfix w-100 d-md-none">
                    <div class="side-links mx-auto">


                        <p class="font-weight-bold text-uppercase">Legal Info</p>
                        <ul class="list-unstyled">
                            <li>
                                <a class="url" href="https://projectelixiros.com/disclaimer">Disclaimer</a>
                            </li>
                            <li>
                                <a class="url" href="https://projectelixiros.com/terms">Terms & Conditions
                                </a>
                            </li>
                            <li>
                                <a class="url" href="https://projectelixiros.com/privacy">Privacy Policy</a>
                            </li>

                        </ul>

                    </div>

                </div>

            </div>

            <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                data-full-width-responsive="true">
            </ng2-adsense>

            <br>

            <!-- Made by devs -->
            <p class=" text-base lg:text-xl md:text-xl text-gray-500 mt-4 flex flex-row card-text text-center">Made with
                <svg class="mx-1 mt-1 heart" width="20" height="20" viewBox="0 0 22 20" fill="none"
                    xmlns="https://www.w3.org/2000/svg">
                    <path
                        d="M21.4355 4.2008C21.0952 3.41277 20.6045 2.69866 19.9908 2.09846C19.3767 1.49646 18.6526 1.01806 17.858 0.689276C17.034 0.346993 16.1503 0.171792 15.258 0.173846C14.0063 0.173846 12.785 0.516619 11.7236 1.16408C11.4697 1.31896 11.2285 1.48908 11 1.67443C10.7715 1.48908 10.5303 1.31896 10.2764 1.16408C9.21504 0.516619 7.99375 0.173846 6.74199 0.173846C5.84062 0.173846 4.96719 0.346502 4.14199 0.689276C3.34473 1.01935 2.62617 1.49416 2.00918 2.09846C1.39472 2.69798 0.90387 3.41226 0.564453 4.2008C0.211523 5.02092 0.03125 5.89182 0.03125 6.7881C0.03125 7.63361 0.203906 8.51467 0.54668 9.41096C0.833594 10.16 1.24492 10.9369 1.77051 11.7215C2.60332 12.9631 3.74844 14.258 5.17031 15.5707C7.52656 17.7467 9.85996 19.2498 9.95898 19.3108L10.5607 19.6967C10.8273 19.8668 11.1701 19.8668 11.4367 19.6967L12.0385 19.3108C12.1375 19.2473 14.4684 17.7467 16.8271 15.5707C18.249 14.258 19.3941 12.9631 20.227 11.7215C20.7525 10.9369 21.1664 10.16 21.4508 9.41096C21.7936 8.51467 21.9662 7.63361 21.9662 6.7881C21.9688 5.89182 21.7885 5.02092 21.4355 4.2008V4.2008Z"
                        fill="#FF0000"></path>
                </svg>
                by <strong>Tulika & Saurav</strong>
            </p>

            <!-- Copyright -->
            <div class="text-center">Copyright © 2021 - {{currentYear}} :
                <a class="url2 py-3" href="https://projectelixiros.com/"> Project Elixir </a> All Rights Reserved
            </div>

            <div>
                <p style="text-align: center; padding-bottom: 3rem;">Any trademarks or images used on this site are
                    property of their respective owners</p>
            </div>

            <!-- Copyright -->

        </footer>

    </div>
</footer>