<div class="container" style="display: block;">
  <!-- <p class="card-text text-uppercase device" style="font-weight: 600; font-size: 1rem; text-align: center;">
    {{data.deviceName}} </p>
  <p class="card-text devicecode text-muted"
    style="font-weight: 500; font-size: 2rem; text-align: center; padding-bottom: 2rem;"> ({{data.codeName}}) </p> -->

      <div class="container">
        <!-- <perfect-scrollbar class="ps"> -->
        <div class="row device-card">
          
          <!-- <div class="col card device-card-left text-center">

            <img src="./assets/images/Common-device.webp" class="card-img-top rounded mx-auto d-block"
              style="padding: 1.5rem 0 1.5rem 0;" alt="device">
            <div class="card-body">
              <p *ngIf="data.deviceStatus =='active'" class="card-text"> <strong class="deviceinfo">Status
                  :</strong> <span class="badge badge-success">{{data.deviceStatus}}</span> </p>
              <p *ngIf="data.deviceStatus =='discontinued'" class="card-text"> <strong class="deviceinfo">Status
                  :</strong> <span class="badge badge-danger">{{data.deviceStatus}}</span> </p>
              <p class="card-text"> <strong class="deviceinfo">Version :</strong> {{data.buildVersion}} </p>
              <p class="card-text"> <strong class="deviceinfo">Maintainer :</strong> {{data.maintainer}} </p>

            </div>
          </div> -->
         
          <div class="col justify-content-center device-card-right" style="text-align: center;">

            <a href="{{data.directdownloadLink}}">
              <p><a class="btn device-btn">Download Build</a></p>
            </a>
            <a href="https://projectelixiros.com/donate">
              <p><a class="btn device-btn">Donate Now</a> </p>
            </a>
            <a href="{{data.telegram}}">
              <p><a class="btn device-btn">Telegram Group</a></p>
            </a>
            <a href="{{data.guide}}">
              <p><a class="btn device-btn">Installation Guide</a></p>
            </a>
            <a href="{{data.xdaLink}}">
              <p><a class="btn device-btn">XDA Thread</a></p>
            </a>
            <a href="{{data.deviceChange}}">
              <p><a class="btn device-btn">Device Changelog</a></p>
            </a>

          </div>
        
        </div>
      <!-- </perfect-scrollbar> -->
      </div>

      <!-- <p style="text-align: center;">
        <button class="btn btn-primary" type="button" (click)="onNoClick()"> Close </button>
      </p> -->

 

</div>