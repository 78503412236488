import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.scss']
})
export class DonateComponent implements OnInit {

  gpay_id = "dwarmachine24@oksbi";
  paytm_id = "dwarmachine24@paytm";
  bitcoin_id = "3Pm6JtwqCuTfKe1Szn23C8ZutF32eFtzf9";
  durationInSeconds = 5;
  showFooter:boolean

  constructor(private _snackBar: MatSnackBar, private router:Router) { }

  ngOnInit(): void {
    this.showFooter=(this.router.url === '/donate') ? true : false;
   }

  openSnackBar() {
    this._snackBar.openFromComponent(SnackComponent, {
      duration: this.durationInSeconds * 500,
      horizontalPosition: 'center',
      verticalPosition:'bottom'
    });
  }
}

@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar-component.html',
  styles: [`
   
    ::ng-deep .mat-snack-bar-container{ 
      background-color: #d4edda;
      border-color: #c3e6cb;
    }
    .snackbox {
      color: #155724;
    }
  `],
})
export class SnackComponent {}