<div *ngIf="showFooter" style="background-color: #ffe9e9;">
    <h1 class="docpage_head">Be a part of our <strong class="key-high">Team</strong> today</h1>
</div>

<div class="container">
    <div class="container website_animation">

        <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
            style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
            data-full-width-responsive="true">
        </ng2-adsense>

        <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
            style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
            data-full-width-responsive="true">
        </ng2-adsense>

        <div class="card-deck ">

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/apply.webp" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Maintainers Code</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://github.com/Project-Elixir/docs/blob/UNO/maintainers_requirements.md"
                        style="text-align: center;">
                        <p><a class="btn shadow device-btn">Code of Conduct</a></p>
                    </a>

                </div>
            </div>

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/device_req.webp" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Device Req"</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://github.com/Project-Elixir/docs/blob/master/device_requirements.md"
                        style="text-align: center;">
                        <p><a class="btn shadow device-btn">Check Device</a></p>
                    </a>

                </div>
            </div>

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/Maintainership.webp" class="card-img-top" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Maintainership Form</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://docs.google.com/forms/d/1eme8i0nXFNpv2fEfbskoANIwLUGy4KcYXssluWv6obE"
                        style="text-align: center;">
                        <p><a class="btn shadow device-btn">Get Application</a></p>
                    </a>

                </div>
            </div>

        </div>

        <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
            style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
            data-full-width-responsive="true">
        </ng2-adsense>

        <div class="card-deck ">

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/mani.webp" class="card-img-top3" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Manifest - WIP</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://github.com/Project-Elixir/manifest" style="text-align: center;">
                        <p><a class="btn shadow device-btn">Sync Manifest</a></p>
                    </a>

                </div>
            </div>

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/xda_temp.webp" class="card-img-top4" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Update Channel</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://telegram.me/Elixir_Updates"
                        style="text-align: center;">
                        <p><a class="btn shadow device-btn">Join Channel</a></p>
                    </a>

                </div>
            </div>

            <div class="card shadow-light p-3 mb-5 bg-body rounded">
                <img src="./assets/images/telegram_support.webp" class="card-img-top6" alt="...">
                <div class="card-body">
                    <h5 class="card-title">Telegram Group</h5>
                    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
                        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ng2-adsense>
                    <a href="https://telegram.me/Elixir_Discussion" style="text-align: center;">
                        <p><a class="btn shadow device-btn">Open Telegram</a></p>
                    </a>

                </div>
            </div>

        </div>
    </div>

    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
    </ng2-adsense>

    <div class="container device-card shadow-light p-3 mb-5 bg-body rounded website_animation">

        <div class="container support_card">
            <p class="support_line">Frequently used files are here</p>
            <p class="support_sum">Well as we have seen many users looking for the right files everyday so we have
                decided to put all the useful files which are frequently asked by users here so they can get all in a
                small
                place. Some of the files are universal and some are device specific. We will try to mention all the
                necessary details with the file.
            </p>
            <div>
                <a href="https://projectelixiros.com/archive">
                    <p> <a class="btn shadow archive-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 -960 960 960" width="25"
                                fill="white">
                                <path
                                    d="M180-80q-24.75 0-42.375-17.625T120-140v-483q-17-6-28.5-21.388Q80-659.776 80-680v-140q0-24.75 17.625-42.375T140-880h680q24.75 0 42.375 17.625T880-820v140q0 20.224-11.5 35.612Q857-629 840-623v483q0 24.75-17.625 42.375T780-80H180Zm0-540v480h600v-480H180Zm-40-60h680v-140H140v140Zm220 260h240v-60H360v60Zm120 40Z" />
                            </svg> Archives
                        </a>
                    </p>
                </a>
            </div>
        </div>

    </div>

    <ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
        style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
    </ng2-adsense>

    <!-- <div *ngIf="showFooter">
        <h1 class="features "> OFF - Topic <strong class="key-high">Highlights</strong> </h1>
    </div>

    <div class="card mb-3 features_card container ">
        <div class="row no-gutters feature_img" style="text-align: center;">
            <div class="col-md-4">
                <img src="./assets/images/youtube.webp" class="card-img faq" alt="img">
            </div>
            <div class="col-md-8" style="margin-top: 1rem;">
                <div class="card-body">
                    <h5 class="card-title f_head2 ">Subscribe & Share<strong class="muted_text"> will
                            be really appreciate
                        </strong></h5>
                    <p class="card-text">Long story short, my friends and I have been travelling in India for the last
                        couple of
                        years. So we
                        decided to create a YouTube channel for fun to drop our experience and share our little world in
                        shorts
                        and
                        small videos.</p>
                    <p class="card-text">Our videos consist of spontaneous vacations from our life. With an emphasis on
                        traveling, personal health
                        & well-being, these vlogs are meant to encourage everyone to go out, explore, and make the most
                        out of
                        every situation they come across.</p>
                    <a href="https://www.youtube.com/channel/UCEq3paOdI2-0z6q4gw3QHgg">
                        <a class="btn shadow archive-btn" style="margin-top: 3rem;">Subscribe Now</a>
                    </a>

                </div>
            </div>
        </div>
    </div> -->

</div>

<ng2-adsense [adClient]="'ca-pub-1149172094904803'" [adSlot]="1318280513"
    style="display:block;text-align: center; margin: 2rem;" data-ad-format="auto" data-full-width-responsive="true">
</ng2-adsense>

<!-- Footer Support Card -->
<div class=" mb-5 bg-body " style="background-color: #ffe9e9; padding: 2rem;">
    <div class="row justify-content-center">
        <div class=" mb-3 card-radius">
            <div class="row no-gutters">

                <div class="col-md-6">
                    <div class="card-body">
                        <h2 class="support_line"> Wanna Support Us ? </h2>
                        <p class="patreon_highlight">Help to keep Project Elixir active and running by donating. Any
                            amount is appreciated.<strong>シ </strong>
                        </p>
                        <h1> <a href="https://projectelixiros.com/donate">
                                <a class="btn shadow footer-support-btn">Donate Now</a>
                            </a></h1>

                    </div>
                </div>

                <div class="col-md-6 mb-align" style="text-align: center;">
                    <img src="./assets/images/donate-phone-hand.webp" class="card-img img-fluid down-carpet-img"
                        style="width: 15rem;" alt="avatar">
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="showFooter">
    <app-footer></app-footer>
</div>