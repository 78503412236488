import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ElixirService } from '../elixir.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  teamList:any = [];
  exteamList:any = [];
  mainatinerList:any = [];
  showMaintainerList:boolean;
  windowScrolled: boolean;

  constructor(private elixirService : ElixirService, private router:Router) { }

  ngOnInit(): void {
    this.elixirService.getTeamMembers().subscribe(data =>  {
      this.teamList = data;
    })
    this.elixirService.getexTeamMembers().subscribe(data =>  {
      this.exteamList = data;
    })
    this.elixirService.getMaintainers().subscribe(maintainer =>  {
      this.mainatinerList = maintainer;
    })
    this.showMaintainerList = (this.router.url === '/team') ? true : false;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      } 
     else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }
  
  scrollToTop() {
      (function smoothscroll() {
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
      })();
  }

}

