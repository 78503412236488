import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class ElixirService {

  private teamServiceUrl ='./assets/json/coremen.json';
  private exteamServiceUrl ='./assets/json/excoremen.json';
  private maintainerServiceUrl = './assets/json/maintainer.json';
  private downloadServiceUrl = './assets/json/download.json';
  private archiveUrl = './assets/json/archive.json'

  constructor(private http: HttpClient) { }

  getTeamMembers() {
    return this.http.get<Config>(this.teamServiceUrl);
  }

  getexTeamMembers() {
    return this.http.get<Config>(this.exteamServiceUrl);
  }

  getMaintainers(){
    return this.http.get<Config>(this.maintainerServiceUrl);
  }

  getDownloadList(){
    return this.http.get<Config>(this.downloadServiceUrl);
  }

  getArchiveDetails(){
    return this.http.get<Config>(this.archiveUrl);
  }

}
