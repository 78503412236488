<nav class="navbar navbar-light brand-logo" style="background-color: #ffe9e9;">
  <div class="navbar-brand">
    <a href="https://projectelixiros.com/home" class="navbar-brand"> <img class="header-logo"
        src="./assets/images/elixir-newlogo.webp" alt="Elixir_logo">
    </a>
  </div>

  <div>
    <nav class="navbar navbar-expand-lg">

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/home">Home </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/download">Download</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/team">Team</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/donate">Donate</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/changelog">Changelog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/documentation">Docs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/faq">Faq</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://projectelixiros.com/gallery">Gallery</a>
          </li>

        </ul>

        <div>
          <h1> <a target="_blank" href="mailto:support@projectelixiros.com?subject=From Website related to Elixir query">
              <a class="btn header-contact-btn">
                Contact Us</a>
            </a></h1>
        </div>
      </div>
    </nav>
  </div>

</nav>