import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangelogComponent } from './changelog/changelog.component';
import { DeviceComponent } from './device/device.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { DonateComponent } from './donate/donate.component';
import { DownloadComponent } from './download/download.component';
import { HomeComponent } from './home/home.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TeamComponent } from './team/team.component';
import { TermsComponent } from './terms/terms.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ArchiveComponent } from './archive/archive.component';
import { FaqComponent } from './faq/faq.component';

const routes: Routes = [
  { path:'', redirectTo:'home', pathMatch: 'full'},
  { path:'changelog', component: ChangelogComponent, pathMatch: 'full'},
  { path:'disclaimer', component: DisclaimerComponent, pathMatch: 'full'},
  { path: 'donate', component: DonateComponent, pathMatch: 'full'},
  { path:'download',component: DownloadComponent,pathMatch: 'full'},
  { path:'home', component: HomeComponent, pathMatch: 'full'},
  { path:'privacy', component: PrivacyComponent, pathMatch: 'full'},
  { path:'team', component: TeamComponent, pathMatch: 'full'},
  { path:'terms', component: TermsComponent, pathMatch: 'full'},
  { path:'device/:id', component : DeviceComponent, pathMatch: 'full'},
  { path:'documentation', component : DocumentationComponent, pathMatch: 'full'},
  { path:'gallery', component : GalleryComponent, pathMatch: 'full'},
  { path:'archive', component : ArchiveComponent, pathMatch: 'full'},
  { path:'faq', component : FaqComponent, pathMatch: 'full'}
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
