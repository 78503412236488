import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit {
  showFooter:boolean

  constructor(private router:Router) { }

  ngOnInit(): void {
    this.showFooter=(this.router.url === '/documentation') ? true : false;
  }

}
